// dependencies.
import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header, Footer } from 'src/components/layout'
import Heading from 'src/components/heading/Heading'
import JobPost from 'src/components/pages/careers/JobPost'
import LoadingSymbol from 'src/components/loading-symbol'
import ResponsiveCarousel from 'src/components/responsive-carousel'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'
import fetchJobData from 'src/js/jobs/fetchJobData'
import { useRellax } from 'src/js/utils/hooks'
// constants.
import { CAREERS_EMAIL, GREENHOUSE_URL } from 'src/constants'
// data.
import CareersData from 'src/data/pages/Contents/careers.json'

// Styles & Images
import 'static/careers/scss/styles.scss'

const images = {
  headerSection: {
    aspectRatio: 1.8,
    path: '/careers/images/',
    fileType: 'jpg',
    webp: true,
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1536,
      },
      {
        fileName: 'header-bg-lsize',
        width: 3200,
      },
    ],
  },
}

const iconSVG = (icon, title) => {
  switch (icon) {
    case 'ExodusLogo':
      return (
        <svg
          className="x-why-work__chrome"
          version="1.1"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd" opacity=".2">
            <path
              d="M19.8802 5.5843L11.3633 0V3.12221L16.827 6.67261L16.1842 8.70651H11.3633V11.2935H16.1842L16.827 13.3274L11.3633 16.8778V20L19.8802 14.4335L18.4875 10.0089L19.8802 5.5843Z"
              fill="#1A1D40"
            />
            <path
              d="M3.95338 11.2935H8.75643V8.70651H3.93553L3.3106 6.67261L8.75643 3.12221V0L0.239502 5.5843L1.63221 10.0089L0.239502 14.4335L8.77429 20V16.8778L3.3106 13.3274L3.95338 11.2935Z"
              fill="#1A1D40"
            />
          </g>
        </svg>
      )
    case 'OpenCulture':
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="x-why-work__feature1">
              <stop stopColor="#556FF2" offset="0%" />
              <stop stopColor="#00BFFF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle cx="32" cy="32" r="32" fill="none" />
            <circle cx="32" cy="32" r="32" fill="url(#x-why-work__feature1)" />
            <g fill="#FFF" fillRule="nonzero">
              <path d="M20.8 26.488v-.26a4.71 4.71 0 0 1 1.082-2.826 4.096 4.096 0 0 1 1.444-1.099l2.963-1.158c.392-.146.683-.205.8-.066a.155.155 0 0 1 .01.015l.753 1.642c1.442.145 2.546 1.468 2.548 3.053v.7a.358.358 0 0 1 .249-.089c.36.032.592.741.518 1.584-.072.83-.413 1.476-.767 1.467v.398c0 1.915-1.991 4.841-4.8 4.841-2.81 0-4.8-2.925-4.8-4.84v-.399c-.354.01-.696-.637-.768-1.467-.074-.843.159-1.552.519-1.584a.359.359 0 0 1 .249.088zm8.484 9.146l4.544 1.648a2.18 2.18 0 0 1 1.372 2.033V43H16v-3.685a2.178 2.178 0 0 1 1.372-2.033l4.595-1.67c.774.934 1.956 1.4 3.633 1.43 1.73.03 2.92-.448 3.684-1.408z" />
              <path
                d="M35.657 27.407v-.217a3.859 3.859 0 0 1 .927-2.355c.34-.39.759-.706 1.238-.916l2.54-.965c.336-.122.585-.17.685-.055a.13.13 0 0 1 .01.013l.645 1.368c1.236.121 2.182 1.223 2.184 2.544v.583a.312.312 0 0 1 .213-.073c.308.026.508.617.445 1.32-.063.69-.355 1.23-.658 1.222v.332c0 1.596-1.707 4.034-4.115 4.034-2.408 0-4.114-2.438-4.114-4.034v-.332c-.303.007-.596-.531-.658-1.222-.063-.703.136-1.294.445-1.32a.313.313 0 0 1 .213.073zm.914 13.76v-1.852a3.553 3.553 0 0 0-2.237-3.311l-.242-.088 2.566-.906c.663.778 1.676 1.167 3.113 1.191 1.483.025 2.504-.372 3.158-1.173l3.895 1.374A1.817 1.817 0 0 1 48 38.096v3.07H36.571z"
                opacity=".4"
              />
            </g>
          </g>
        </svg>
      )
    case 'RemotePositions':
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="x-why-work__feature2">
              <stop stopColor="#420BE6" offset="0%" />
              <stop stopColor="#A16BB3" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle cx="32" cy="32" r="32" fill="none" />
            <circle cx="32" cy="32" r="32" fill="url(#x-why-work__feature2)" />
            <path fill="#FFF" opacity=".3" d="M22.467 37h19.066L45 45H19z" />
            <path
              d="M32 20c-3.933 0-8 3.048-8 8.129 0 4.81 7.2 12.33 7.533 12.668A.6.6 0 0 0 32 41a.6.6 0 0 0 .467-.203C32.8 40.458 40 33.007 40 28.129 40 23.049 35.933 20 32 20zm0 11.308c-1.76 0-3.2-1.454-3.2-3.231s1.44-3.23 3.2-3.23c1.76 0 3.2 1.453 3.2 3.23s-1.44 3.23-3.2 3.23z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      )
    case 'HardwareSoftware':
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="x-why-work__feature3">
              <stop stopColor="#5C26FF" offset="0%" />
              <stop stopColor="#8C66FF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle cx="32" cy="32" r="32" fill="none" />
            <circle cx="32" cy="32" r="32" fill="url(#x-why-work__feature3)" />
            <g fill="#FFF">
              <path
                d="M22 39h7v2h6v-2h7V24H22v15zm-3 0V22a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v17h3v3a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1v-3h3z"
                fillRule="nonzero"
              />
              <path opacity=".3" d="M22 24h20v3l-20 9z" />
            </g>
          </g>
        </svg>
      )
    case 'BTCSalary':
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="x-why-work__feature4">
              <stop stopColor="#FF9F19" offset="0%" />
              <stop stopColor="#FC3" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle cx="32" cy="32" r="32" fill="none" />
            <circle cx="32" cy="32" r="32" fill="url(#x-why-work__feature4)" />
            <path
              d="M33.732 15.956l11.536 6.548A3.395 3.395 0 0 1 47 25.453v13.094c0 1.217-.66 2.341-1.732 2.95l-11.536 6.547a3.516 3.516 0 0 1-3.464 0l-11.536-6.548A3.395 3.395 0 0 1 17 38.547V25.453c0-1.217.66-2.341 1.732-2.95l11.536-6.547a3.516 3.516 0 0 1 3.464 0z"
              fill="#FFF"
              opacity=".3"
            />
            <path
              d="M35.45 37.749c-1.078.37-2.083.217-2.53.155-.22-.03-.592-.099-.99-.182l-.635 2.371-1.514-.405.625-2.333-1.154-.31-.625 2.334-1.514-.406.625-2.333-2.979-.798.781-1.67.906.243c.304.081.439.088.597.007.158-.082.234-.252.273-.395l1.618-6.04c.101-.378.097-.489-.032-.732-.128-.243-.546-.382-.785-.446l-.875-.235.406-1.515 2.98.798.626-2.34 1.514.407-.627 2.339 1.154.309.627-2.34 1.514.406-.634 2.368c1.11.37 1.895.802 2.489 1.521.767.93.403 2.09.307 2.363-.096.274-.368.779-.716 1.058-.347.28-1.063.461-1.063.461s.695.347 1.112.86c.415.514.62 1.278.34 2.24-.281.964-.744 1.87-1.821 2.24zm-3.182-5.107c-.738-.249-1.713-.459-1.713-.459l-.838 3.13s.999.29 1.684.423c.685.132 1.422.154 1.818.063.396-.092.89-.266 1.082-.982.192-.716-.223-1.149-.47-1.361-.245-.213-.826-.565-1.563-.814zm-.064-1.574c.558.127 1.178.162 1.662.05.485-.11.82-.4.95-.937.128-.536-.12-1.084-.693-1.435s-.987-.477-1.46-.61c-.472-.134-.964-.223-.964-.223l-.754 2.817s.702.21 1.26.338z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      )
    default:
      return null
  }
}

// Partials:
const BackToTop = () => (
  <a id="back-to-top" href="#" className="x__back-to-top" role="button" title="To the top">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title>To the top</title>
      <line x1="12" y1="20" x2="12" y2="4" />
      <polyline points="6 10 12 4 18 10" />
    </svg>
  </a>
)

const HeaderSection = ({ data, onClick }) => {
  useRellax({ className: 'careers-rellax' })

  return (
    <header className="x__header">
      <div className="x__header-darken" id="x__header-darken" />
      <div className="x__header-bg-container careers-rellax" data-rellax-speed="-4">
        <Img
          fluid={buildImageObject(images.headerSection)}
          durationFadeIn={2000}
          className="x__header-bg"
          alt={data.imageAlt}
        />
      </div>
      <div className="x__header-content">
        <div className="x__header-txt-container">
          <span className="x__header-subheading">{data.subheading}</span>
          <Heading page="careers" className="x__header-heading" />
          <p className="x__header-txt">{data.description}</p>
          <a
            href={onClick ? '#' : GREENHOUSE_URL}
            className="x__button x__button--top-margin"
            id="see-positions"
            target={onClick ? '_self' : '_blank'}
            onClick={onClick}
          >
            <span className="x__button-align">
              <span className="x__button-align-text">{data.button.text}</span>
            </span>
          </a>
        </div>
      </div>
    </header>
  )
}

const WhySection = ({ data }) => (
  <section className="x-why-work">
    {iconSVG('ExodusLogo')}

    <div className="row x__width x-why-work__section1">
      <div className="col-12 col-md-5">
        <Heading type="h2" page="careers" section="why" className="x-h2" />
        <p className="x-why-work__txt">{data.subheading}</p>
        <p className="x-p">{data.description}</p>
      </div>
      <div className="col-12 col-md-7">
        <img
          className="x-why-work__img"
          src="/careers/images/why-work-img.png"
          alt={data.imageAlt}
        />
      </div>
    </div>

    <ResponsiveCarousel
      className="x-why-work__features x__width"
      innerClassName="row"
      normalizeHeights
    >
      {data.info.map((item, i) => {
        return (
          <div key={i} className="col-12 col-md-3 x-why-work__features-item">
            {iconSVG(item.name)}
            <h3>{item.title}</h3>
            <p className="x-p">{item.description}</p>
          </div>
        )
      })}
    </ResponsiveCarousel>
  </section>
)

const PositionsSection = ({ data, anchor }) => (
  <section className="x-open-positions" id="open-positions">
    <div className="x__width">
      <Heading type="h2" page="careers" section="positions" className="x-h2" id="open-positions" />
      {data.length === 0 ? (
        <LoadingSymbol />
      ) : (
        data.map(({ name, id, jobs }) => (
          <div key={id}>
            <h3 className="x-open-positions__department-header">{name}</h3>
            {jobs &&
              jobs.length > 0 &&
              jobs.map((post, p) => (
                <JobPost key={`${id}-${p}`} post={post} openOnMount={post.anchor === anchor} />
              ))}
          </div>
        ))
      )}
    </div>
  </section>
)

const ContactSection = ({ data }) => (
  <section className="x-contact">
    <div className="x__width">
      <Heading type="h2" page="careers" section="contact" className="x-h2" />
      <p>{data.description}</p>

      <a
        href={`mailto:${CAREERS_EMAIL}`}
        className="x__button x__button--top-margin x__button--shadow-light"
      >
        <span className="x__button-align">
          <span className="x__button-align-text">{data.button}</span>
        </span>
      </a>
    </div>
  </section>
)

// Main component:
const CareersPage = () => {
  const [jobs, setJobs] = useState([])
  const [anchor, setAnchor] = useState('')

  /* Fetch jobs data */
  useEffect(() => {
    const fetchJobDataFunc = async () => {
      const jobData = await fetchJobData()
      setJobs(jobData)
    }
    fetchJobDataFunc()
  }, [])

  /* when navigating to a specific job post, open posting */
  useEffect(() => {
    const splitUrl = window.location.href.split('#')
    if (splitUrl.length === 2) setAnchor(splitUrl[1])
  }, [])

  const handleSeePositionsClick = (e) => {
    e.preventDefault()
    document.getElementById('open-positions').scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="x-page-careers">
      <Header />

      <main className="x">
        <HeaderSection data={CareersData.header} onClick={handleSeePositionsClick} />
        <WhySection data={CareersData.why} />
        <PositionsSection data={jobs} anchor={anchor} />
        <ContactSection data={CareersData.contact} />

        <BackToTop />
        <Footer />
      </main>
    </div>
  )
}

export default CareersPage

// <head> component:
export function Head() {
  return <PageHead page="careers" />
}
