import makeRequest from 'src/js/utils/make-request'

import { HELPERS_URL } from 'src/constants'

const fetchJobData = async () => {
  try {
    const body = await makeRequest(`${HELPERS_URL}/jobs`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(),
    })

    return body
  } catch (err) {
    console.warn('Failed to fetch jobs:', err)
    return []
  }
}

export default fetchJobData
