import React, { useEffect, useRef, useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classNames from 'classnames'

import { SITE_URL } from 'src/constants'

const JobPost = ({ post, openOnMount }) => {
  const scrollTarget = useRef(null)
  const [copied, setCopied] = useState(false)
  const [open, setOpen] = useState(false)

  const handleLinkCopy = (e) => {
    e.stopPropagation()
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const handlePostClicked = () => setOpen(!open)

  useEffect(() => {
    if (openOnMount) {
      setOpen(true)
      window.scrollTo({
        top: scrollTarget.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <div key={post.anchor} className="x-open-positions__card">
      <a className="x-open-positions__card-anchor" ref={scrollTarget} />
      <div
        className={classNames('x-open-positions__card-header row', {
          'x-open-positions__card-header--closed': !open,
        })}
        role="button"
        id={post.anchor}
        onClick={handlePostClicked}
      >
        <div className="col-3 col-sm-2 col-md-1 col-lg-1">
          <span onClick={handleLinkCopy}>
            <OverlayTrigger
              key={post.anchor}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${post.anchor}`} className="copy-link-tooltip">
                  {copied ? 'Copied!' : 'Copy Link'}
                </Tooltip>
              }
            >
              <CopyToClipboard text={`${SITE_URL}/careers/#${post.anchor}`}>
                <span className="x-open-positions__card-header-link">
                  <img src="/careers/images/icon-social-website.svg" />
                </span>
              </CopyToClipboard>
            </OverlayTrigger>
          </span>
        </div>
        <div className="col-6 col-sm-8 col-md-9 col-lg-4">
          <h4>
            {post.title}
            {post.openings && <span> ({post.openings})</span>}
          </h4>
        </div>
        <div className="col-3 col-sm-2 col-md-2 col-lg-7 x-open-positions__card-header-info">
          <div className="x-open-positions__card-header-block">
            <span className="x-open-positions__card-label">Location</span>
            <span className="x-open-positions__card-labeltxt">{post.location}</span>
          </div>

          <span className="x-open-positions__card-arrow">
            <svg version="1.1" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd">
                <polyline points="11 1 6 6 1 1" stroke="#531AFF" strokeWidth="1.875" />
              </g>
            </svg>
          </span>
        </div>
      </div>

      <Collapse in={open}>
        <div
          id="collapseposition1"
          className="collapse x-open-positions__card-content"
          aria-labelledby="position1"
        >
          <div className="x-open-positions__card-body row">
            <div className="col-12 col-md-5 x-open-positions__card-body1">
              <h4>{post.title}</h4>
              <p className="x-open-positions__card-body1-txt">{post.tagline}</p>

              <span className="x-open-positions__card-body1-logo" role="presentation" />

              {post.skills && (
                <div className="x-open-positions__card-body1-block">
                  <span className="x-open-positions__card-body1-label">Skills</span>
                  {post.skills.map((text, index) => {
                    return (
                      <p key={index} className="x-open-positions__card-body1-desc">
                        {text}
                      </p>
                    )
                  })}
                </div>
              )}

              <div className="x-open-positions__card-body1-block">
                <span className="x-open-positions__card-body1-label">Location</span>
                <p className="x-open-positions__card-body1-desc">{post.location}</p>
              </div>

              {post.type && (
                <div className="x-open-positions__card-body1-block">
                  <span className="x-open-positions__card-body1-label">Type</span>
                  <p className="x-open-positions__card-body1-desc">{post.type}</p>
                </div>
              )}

              {post.salary && (
                <div className="x-open-positions__card-body1-block x-open-positions__card-body1-salary">
                  {post.salary.length > 0 && (
                    <div className="x-open-positions__card-body1-salary1">
                      <span className="x-open-positions__card-body1-label">Salary</span>
                      <p className="x-open-positions__card-body1-desc">
                        {post.salary.map((item, i) => (
                          <strong key={i}>{item}</strong>
                        ))}
                      </p>
                    </div>
                  )}
                  <div className="x-open-positions__card-body1-salary2">Paid in BTC</div>
                </div>
              )}
            </div>

            <div className="col-12 col-md-7 x-open-positions__card-body2">
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
              <a
                href={`https://boards.greenhouse.io/exodus54/jobs/${post.id}#app`}
                className="x__button x__button--top-margin x__button--shadow-light x__button--margin-auto"
                target="_blank"
              >
                <span className="x__button-align">
                  <span className="x__button-align-text">Apply now for this position</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default JobPost
